import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

import "react-datepicker/dist/react-datepicker.css";

import { Link,useLocation,useNavigate } from "react-router-dom";


import { Col, Container, Row, Form, ProgressBar } from "react-bootstrap";

import 'react-phone-number-input/style.css'

import "swiper/css";
import "swiper/css/navigation";
import Paymenttab from "../components/Paymenttab";
import payman from "../assets/images/book/pay_man.png"
import img_full from "../assets/images/book/img_full.png"
import successdollar from "../assets/images/book/successdollar.png"
import successtick from "../assets/images/book/successtick.png"
import dangertimer from "../assets/images/book/dangertimer.png"
import payment from "../assets/images/book/payment.png"
import roomicon from "../assets/images/book/room_icon.png"
import tagicon from "../assets/images/book/tag_icon.png"
import usericon from "../assets/images/book/user_icon.png"
import verified from "../assets/images/book/verified.png"
import * as Hotels from "../Axios/hotel.axios";
import { DateTimeForm, Decryptdata, EncryptData, Name_showing, isEmpty } from "../lib/common";
import CustomLoader from "../components/CustomLoader";
import { useSelector } from "react-redux";



export default function PaymentOption({stripe}) {
  const [HotelData, setHotelData] = useState(false);
  const [SearchData, setSearchData] = useState(false);
  const [RoomsDetails, setRoomsDetails] = useState([]);
  const [bookingid, setBookingid] = useState('');
  const [Button, setButton] = useState(false);
  const [Buffer, setBuffer] = useState(false);
  const { UserDetails } = useSelector((state) => state.LoginReducer.User);
console.log("UserDetailsUserDetails",UserDetails);

  useEffect(()=>{
   if(isEmpty(UserDetails)&&isEmpty(localStorage.getItem("userInfo"))){
    navigate('/')

    }
  },[])


/**Navigation */
const location =useLocation()
const navigate = useNavigate()

/**Props Data Get */
const searchParams = new URLSearchParams(location?.search);
const Hotelid=searchParams.get('hotelid')
const optionid =searchParams.get('optionid')
const paramsData =searchParams.get('travellerdata')
const index =searchParams.get('index')
const roomid =searchParams.get('roomid')
const searchdata =searchParams.get('searchdata')



const travellerdata =Decryptdata(paramsData)



useEffect(()=>{
ReviewBookingFun()
},[])

// useEffect(()=>{
//   if(HotelData){
//     HotelImgaesdownload()

//   }

// },[HotelData])
console.log("indexindex",travellerdata);

useEffect(()=>{
  let Payment=localStorage.getItem('payment')
  if(Payment =='stripe'){
navigate(
`/reviewBooking?hotelid=${Hotelid}&&roomid=${roomid}&&index=${index}&&searchdata=${searchdata}`
)
  }
})


const ReviewBookingFun = async()=>{
  let SendData={
    hotelId:Hotelid,
    optionId:optionid
  }
  let { status,data } = await Hotels.ReviewBooking(SendData);
  console.log("ReviewBookingReviewBooking",data);
  if(!isEmpty(data)){
    setHotelData(data?.hInfo)
    setSearchData(data?.query)
    setRoomsDetails(data?.hInfo?.ops)
    setBookingid(data?.bookingId)
    setButton(true)

  }

}

// const HotelImgaesdownload =async()=>{
//   try {

//   // const response = await fetch(`https://cors-anywhere.herokuapp.com/${HotelData?.img?.[0]?.url}`, {
//   //   mode: 'cors', // Ensure CORS is enabled
//   // });
//   const response = await fetch(`https://pietrip.maticz.in/${HotelData?.img?.[0]?.url}`, {
//     mode: 'no-cors', // Ensure CORS is enabled
//   });
// // console.log("responseresponse",response);
//   if(response){
//           // Convert the response to a Blob
//           const blob = await response.blob();
//           console.log("blobblobblob",blob);
//           const formData = new FormData();
//           formData.append('Image', blob, 'image.jpg');
//           formData.append('Hotelid', EncryptData(HotelData?.id?.split('-')[1]));


        
//        let Image=await Hotels.Hotel_Img_Download(formData)
//   }
// }
// catch(error){
//   console.error('Error downloading image:', error);

// }

// }
// const HotelImgaesdownload =async()=>{
//   try {
//     let Image=await Hotels.Hotel_Img_Download({Imageurl:HotelData?.img?.[0]?.url,Hotelid:HotelData?.id?.split('-')[1]})

// }
// catch(error){
//   console.error('Error downloading image:', error);

// }

// }
// console.log("BufferBufferBuffer",bookingid);
  return (
 
    <>
      <Header />
   { 
     <div className="search-page particular-room-details paymentOption_Page">
        <div className="search-header">
          <div className="container">
           <p className="filter_text mb-0">Payment Option</p>
          </div>
        </div>
       
       


        {HotelData?
          <Container>
  
        <Row className="justify-content-center mt-4">
            <Col lg={8}>
            </Col>
            <Col lg={4}>
                <p className="safe_text text-end">
                    <img src={verified} className="me-2" />
                    <span>safe & secure</span>
                </p>
            </Col>
            </Row>
            
          <Row className="justify-content-center mt-0 mb-2">
            <Col lg={8}>

                
            <div className="card card_book card_book_badge mb-3">
                <div className="badge_purple_card">Worry Free Hotel Stay!</div>
                <Row className="p-3 mt-5 mt-sm-3">
                <Col sm={3} md={3} className=" d-flex align-items-center justify-content-center">
                        <img src={payman} className="img-fluid" />
                        </Col>
                    <Col md={6} lg={6} sm={6} className="mt-3 mt-sm-0">
                    <p className="black_bold_text mt-0 mb-1">Trip Secure | One plan, Many benefits</p>

              <p className="book_text_light mt-0 mb-0">
              Hassle free stay. Get upto: Rs. 10K for hotel booking cancellation charges if cancelled due to illness, Rs. 20K for bounced booking, 24x7 emergency medical assistance, Rs. 20K for loss of tablets and laptops, Rs. 20K for loss of baggage, Rs. 10K for emergency travel of family members. T&Cs apply.
              </p>
                    </Col>
                    <Col sm={3} md={3} lg={3} className="mt-3 mt-sm-0 text-center text-sm-center d-flex align-items-end justify-content-center flex-column">
                    <button className="btn btn_outline_purple">ADD @ 29</button>
                       <p className="text-center book_text_light mt-1">per person</p>
                        </Col>
                </Row>
                </div>


            <div className="card card_book mb-3 card_book_more_radius">
              <Row className="mx-0">
                <Col md={4} className="bg_pink header_bg">
                <p className="black_bold_text mt-3 mb-3">Payment options</p>

                </Col>
              </Row>
         
<Paymenttab type={"Rooms"}  
travellerdata={travellerdata} 
Bookingid={bookingid} 
hoteldata={HotelData} 
roomsdata={RoomsDetails}
Button={Button}
Searchdata={SearchData}
Stripe={stripe}
setReservation={setBuffer}/>



              </div>
              

              <Row className="mt-0 mb-2">
            <Col md={{span:8, offset:4}}>
              <p className="payment_desc_form"><span>When you click the “Complete Reservation” button, you will see the tab to pay with
Binance. Login to your Binance account and make the payment and your booking
will be processed and confirmed.</span></p>
              </Col>
              </Row>
             
           
            </Col>
            <Col lg={4} className="mt-3 mt-lg-0">

            <div className="card card_book mb-3 card_book_more_radius">
                <img src={HotelData?.img?.[0]?.url?HotelData?.img?.[0]?.url:img_full} className="img-fluid img_pay_full" />

                <div className="purple_gradient">
                    <p className="mb-0">{HotelData?.name}</p>
                    <p className="mb-0">
                    {HotelData?.ad?.adr},{HotelData?.ad?.city?.name}-{HotelData?.ad?.postalCode}, {HotelData?.ad?.state?.name}

</p>
                </div>
          
            <Row className="p-3 align-items-center">
                <Col md={5}>
                    <p className="book_text_bold mb-0">CHECK IN</p>
                    <div className="d-flex align-items-center justify-content-between">
                    <p className="book_text_big mb-0">{SearchData?.checkinDate&&DateTimeForm({date:SearchData?.checkinDate,words:true})}</p>

                    </div>

                </Col>
                <Col md={2}>
                <span className="badge text-dark text-uppercase">-</span>
                    
                </Col>
                <Col md={5}>
                    <p className="book_text_bold mb-0">CHECK OUT</p>
                    <div className="d-flex align-items-center justify-content-between">
                    <p className="book_text_big mb-0">{SearchData?.checkoutDate&&DateTimeForm({date:SearchData?.checkoutDate,words:true})}</p>

                    </div>

                </Col>
               
            </Row>
{     HotelData?.ops?.[0]?.cnp?.ifra&&     <div className="bg_green px-3">
            <div className="d-flex align-items-center">
              <img src={payment} />
              <span>Free cancellation from {DateTimeForm({date:new Date(HotelData?.ops?.[0]?.cnp?.pd?.[0].fdt),words:true})} to {DateTimeForm({date:new Date(HotelData?.ops?.[0]?.cnp?.pd?.[0].tdt),words:true})}</span>
            </div>

          </div>}

            <div className="px-3 mt-3">
              <div className="d-flex align-items-start justify-content-start mb-3">
                <img src={roomicon} className="book_icons mt-1" />
                <div>
                <p className="black_bold_text_ssm mt-1 mb-0 ms-3">Rooms</p>
            {/* <p className="book_text_light">2 Adults</p> */}
            </div>
              </div>

              <div className="d-flex align-items-start justify-content-start mb-3">
                <img src={tagicon} className="book_icons mt-1" />
                <div>
                  {console.log("RoomsDetailsRoomsDetails",RoomsDetails)}
                <p className="black_bold_text_ssm mt-0 mb-0 ms-3">{RoomsDetails[0]?.ris?.[0]?.rc??""} <span></span></p>
            <p className="book_text_light_ssm mb-0 ms-3">{RoomsDetails?.ris?.[0]?.mb??""}</p>
            </div>
              </div>


              <div className="d-flex align-items-start justify-content-start mb-3">
                <img src={usericon} className="book_icons mt-1" />
                <div>
                <p className="black_bold_text_ssm mt-0 mb-0 ms-3">Traveler(s)</p>
            <p className="book_text_light_ssm mb-0 ms-3 text-uppercase">{Name_showing(travellerdata[0].travellerInfo?.[0].fN + travellerdata[0].travellerInfo?.[0]?.lN)}</p>
            <p className="book_text_light_ssm mb-0 ms-3"><span>{Name_showing(travellerdata[0]?.Contact?.Email)}</span> | <span>{Name_showing(travellerdata[0]?.Contact?.MobileNumber)}</span></p>

            </div>
              </div>

          
            </div>

             

            

         
              </div>


           

              <div className="card card_success mb-3 p-3">
                <div className="d-flex align-items-center">
                <img src={successdollar} className="icon_card_img me-3" />

                  <div>
                    <p className="mb-0">Congrats! You’ll receive approx.</p>
                    <p className="mb-0"><span>US$1.98</span> giveback in Trip on this booking</p>
                  </div>

                </div>
              </div>

              <div className="card card_success mb-3 p-3">
                <div className="d-flex align-items-center">
                <img src={successtick} className="icon_card_img me-3" />

                  <div>
                    <p className="mb-0 text_green">Earn a proof-of-travel NFT stamp for this booking</p>
                  </div>

                </div>
              </div>

              <div className="card card_danger mb-3 p-3">
                <div className="d-flex align-items-center">
                <img src={dangertimer} className="icon_card_img me-3" />

                  <div>
                    <p className="mb-0 text_red">Hurry! We only have 5 rooms left at this price</p>
                  </div>

                </div>
              </div>


              <div className="card card_book mb-3 card_book_more_radius">
              
          

              <div className="px-3 py-3">
              
  
                <div className="row">
                  <div className="col-7 col-md-8">
                    <p className="room_dark room_dark_big mb-0">Fare Summary</p>
  
                  </div>
                  <div className="col-5 col-md-4">
                    <p className="text-end room_dark_purple mb-0">Close</p>
                    
                    </div>
                </div>
                     <hr className="hr_grey"/>
  
                    
  
                     <div className="row">
                  <div className="col-7 col-md-8">
                    <p className="room_dark mb-0">Hotel Fare</p>
  
                  </div>
                  <div className="col-5 col-md-4">
                    <p className="text-end room_dark mb-0">₹ {HotelData?.ops?.[0]?.tp}</p>
                    
                    </div>
                </div>
                     <hr className="hr_grey"/>
  
                     <div className="row">
                  <div className="col-7 col-md-8">
                    <p className="room_dark mb-0">Others
                  </p>
  
                  </div>
                  <div className="col-5 col-md-4">
                    <p className="text-end room_dark mb-0">₹ 437</p>
                    
                    </div>
                </div>
                     <hr className="hr_grey"/>

                     <div className="row">
                  <div className="col-7 col-md-8">
                    <p className="room_dark_purple mb-0">Instant Coupon - WELCOMEMMT
                   </p>
  
                  </div>
                  <div className="col-5 col-md-4">
                    <p className="text-end room_dark_purple mb-0">- ₹ 591</p>
                    
                    </div>
                </div>
                     <hr className="hr_grey"/>
                     
                     <div className="row">
                  <div className="col-7 col-md-8">
                    <p className="room_dark_footer_bold mb-0">Total Due</p>
  
                  </div>
                  <div className="col-5 col-md-4">
                    <p className="text-end room_dark_footer_bold mb-0">₹ {HotelData?.ops?.[0]?.tp}</p>
                    
                    </div>
                </div>
              </div>
  
                
  
                 
  
            
                </div>

                <p className="room_dark_purple">Over 53 million satisfied TripFi customers.</p>


           
            
                </Col>
          </Row>

        </Container>
        :
        <CustomLoader/>
        }
      </div>
      
     

      }
      {/* <div style={{ position: "relative" }} className="">
               <BookingConfirmation/>

               
     </div> */}
      <Footer />



     
    </>
  );
}
