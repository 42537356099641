import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import FaqAccor from "./FaqAccor";
import * as CMS from "../Axios/cms.axios";
import NodataFound from "./NodataFound";

function Faqs() {
  const [faqcategory, setfaqcategory] = useState([]);
  const [faqdata, setfaqdata] = useState([]);

  const [Tabname, SetTabname] = useState("");

  useEffect(() => {
    GetFaqcategory();
  }, []);

  useEffect(() => {
    GetFaq(Tabname);
  }, [Tabname]);

  const GetFaqcategory = async () => {
    let { success, data } = await CMS.Get_Faqcategory({});
console.log("datadatadata",data);
    setfaqcategory(data);

    SetTabname(data?.[0]?.Name);
  };

  const GetFaq = async (Value) => {
    console.log("CCCCCCc",Value);
    let { success, data } = await CMS.Get_Faq({ Category: Value });
console.log("Faq_data",data);
    setfaqdata(data);
  };
  return (
    <div className="container custom-container">
      <div className="faqs">
        <div className="header-sec text-center">
          <h3 className="mb-0 fw-700 header-clr">FAQS</h3>
        </div>
        <div className="faq-custom-tabs mt-4">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={0}
            className=""
          >
            <div className="row">
              <div className="col-12 col-sm-12 sol-md-12 col-lg-12 col-xl-3 mb-4">
                <Nav variant="pills" className="flex-column">
                  {faqcategory?.length > 0 &&
                    faqcategory?.map((item, index) => (
                      <Nav.Item
                        onClick={() => SetTabname(item?.Name)}
                        key={index}
                      >
                        <Nav.Link eventKey={index.toString()} className="">
                          <span className="left-active-bdt"></span>
                          {item?.Name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <div className="col-12 col-sm-12 sol-md-12 col-lg-12 col-xl-9">
                <Tab.Content>
                  {faqcategory?.map((tab, index) => {
                    return (
                      <>
                        <Tab.Pane eventKey={index.toString()} key={index}>
                          <div className="content-sec">
                            <FaqAccor Faqdata={faqdata} />
                          </div>
                        </Tab.Pane>
                      </>
                    );
                  })}
                </Tab.Content>
              </div>
            </div>
       {faqcategory?.length ==0 && <NodataFound /> }
          </Tab.Container>
        </div>
      </div>
    </div>
  );
}

export default Faqs;
